import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/global/layout/layout';
import Slice from '../components/global/slice/slice';

export const pageQuery = graphql`
  {
    prismicHomepage {
    data {
      meta_title {
        text
      }
      meta_image {
        url
      }
      meta_description {
        text
      }
      body {
        ... on PrismicHomepageBodyIframe {
          primary {
            html {
              text
            }
            responsive
          }
        }
        ... on PrismicHomepageBodySchlagworteText {
          primary {
            bulletpoints {
              raw
            }
            text {
              html
            }
          }
        }
        ... on PrismicHomepageBodyBilderLinks {
          items {
            image {
              url
            }
            link {
              text
            }
            text {
              text
            }
          }
        }
        ... on PrismicHomepageBodyBild {
          primary {
            color
            image {
              alt
              url
            }
            link {
              text
            }
            link_text {
              text
            }
            row_1 {
              text
            }
            row_2 {
              text
            }
            row_3 {
              text
            }
          }
        }
        ... on PrismicHomepageBodyTextabschnitte {
          items {
            text {
              html
            }
            heading {
              text
            }
          }
        }
        ... on PrismicHomepageBodyText {
          primary {
            text {
              html
            }
          }
        }
        ... on PrismicHomepageBodyPersonenBereich {
          primary {
            heading {
              text
            }
          }
          items {
            text {
              html
              text
            }
            position {
              text
              raw
            }
            lastname {
              text
            }
            image {
              url
              alt
            }
            firstname {
              text
            }
            email {
              text
            }
            phone {
              text
            }
          }
        }
        ... on PrismicHomepageBodyBildergalerie {
          items {
            image {
              url
              alt
            }
          }
        }
        ... on PrismicHomepageBodyProjektTeaser {
          items {
            use_video
            video_link {
              text
            }
            video_thumbnail {
              alt
              url
            }
            image {
              alt
              url
            }
            link {
              text
            }
            link_text {
              text
            }
            text {
              html
            }
            title {
              text
            }
          }
        }
        # ... on PrismicHomepageBodyZitat {
        #   primary {
        #     author {
        #       text
        #     }
        #     image {
        #       alt
        #       url
        #     }
        #     text {
        #       html
        #     }
        #   }
        # }
        # ... on PrismicHomepageBodyVorteile {
        #   primary {
        #     heading {
        #       text
        #     }
        #     image {
        #       url
        #       alt
        #     }
        #     items {
        #       raw
        #     }
        #   }
        # }
        ... on PrismicHomepageBodyBildHeader {
          primary {
            color
            image {
              alt
              url
            }
            row_1 {
              text
            }
            row_2 {
              text
            }
            row_3 {
              text
            }
          }
        }
        ... on PrismicHomepageBodyUberschriftUndText {
          primary {
            text {
              html
            }
            heading {
              text
            }
          }
        }
        # ... on PrismicHomepageBodyProzessKarten {
        #   items {
        #     text {
        #       html
        #     }
        #     title {
        #       text
        #     }
        #   }
        # }
      }
    }
  }
}
`

export default ({ data }) => (
  <Layout title={data.prismicHomepage.data.meta_title.text}
                description={data.prismicHomepage.data.meta_description.text}
                image={data.prismicHomepage.data.meta_image.url}>
    <Slice slices={data.prismicHomepage.data.body}/>
  </Layout>
);
